<template>
    <div class="users">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <breads :links="breadLinks"/>
                    <PageHeader :title="$t('Cinemas')" :button-caption="$t('Add a movie theater')" @pageHeaderButtonClick="addCinema"/>
                    <div class="page-content">
                        <TableMain
                            :items="cinemas"
                            :fields="fields"
                            @update="updateCinema"
                            @getItems="getCinemas"
                            :hideActions="false"
                            :header-columns="columns"
                            :valuesFromRoute="true"
                            :itemsMeta="cinemasMeta"
                            :preloader="preloader"
                            :showDelete="true"
                            @getItemForDelete="getItemForDelete"
                        />
                    </div>
                    <ModalComponent :show="showModal.form" @close="showModal.form = false;" :ask-before-leave="askBeforeLeave" :ask-leave-action="askLeaveAction">
                        <div class="add-cinema">
                            <h2 class="typography typography--h2 add-cinema__title">{{ isEditForm ? $t('Edit movie theater') : $t('Add a movie theater') }}</h2>
                            <FormComponent
                                @close="$emit('close')"
                                :model="cinemasForm"
                                :noRedirect="true"
                                :method="isEditForm ? 'put' : 'post'"
                                :error="errorHandler"
                                :removeBeforeSubmit="{ removedHalls, type: 'halls' }"
                                @success="success" :action="newAddCinemaAction"
                                @isFormDisable="isFormDisable"
                            >
                                <div class="row align-items justify-content-center">
                                    <div class="col-md-6 col-12">
                                        <div class="mb-3">
                                            <InputComponent :required="true" name="name" v-model="cinemasForm.name" :error="errors.name" :showLabel="true" :placeholder="$t('Name')"/>
                                        </div>
                                        <div class="mb-3">
                                            <InputComponent :required="true" name="custom_id" v-model="cinemasForm.custom_id" :error="errors.custom_id" :showLabel="true" :placeholder="$t('ID')"/>
                                        </div>
                                        <div class="mb-3">
                                            <SelectComponent
                                                ref="getcompanies"
                                                @input="companiesChange"
                                                :clearable="false"
                                                :lightblue="true"
                                                :options="companies"
                                                :required="true"
                                                :error="errors.company_id"
                                                :show-label="true"
                                                :placeholder="$t('Company')"
                                                @search="searchCompanies"
                                                :value="cinemasForm.company_id"
                                                name="company_id"
                                              />
                                        </div>
                                        <CheckboxComponent :value="cinemasForm.is_active" :label="$t('Is active')" @change="isCinemaActiveChange" name="isCinemaActive" />

                                    </div>
                                    <div class="col-md-6 col-12 mt-md-0 mt-3">
                                        <!-- <div class="mb-3">
                                            <InputComponent name="api_name" v-model="cinemasForm.api_name" :error="errors.api_name" :showLabel="true" :placeholder="$t('pages.catalog.cinemas.form.Api name')"/>
                                        </div> -->
                                        <div class="mb-3">
                                            <SelectComponent v-if="isNetworksLoaded" @input="networksChange" :lightblue="true" :options="networks" :error="errors.network_id" :show-label="true" :placeholder="$t('Network')" :value="cinemasForm.network_id" name="network_id"/>
                                        </div>
                                        <div class="mb-3">
                                            <SelectComponent
                                                ref="getcities"
                                                @input="citiesChange"
                                                :clearable="false"
                                                :lightblue="true"
                                                :options="cities"
                                                :required="true"
                                                :error="errors.region_id"
                                                :show-label="true"
                                                :placeholder="$t('City')"
                                                @search="searchCities"
                                                :value="cinemasForm.region_id"
                                                name="region_id"
                                            />
                                        </div>
                                    </div>

                                    <div class="row p-0">

                                        <div class="col-12">
                                            <hr class="add-cinema__hr">
                                            <h2 class="typography typography--h2 add-cinema__title">{{ isEditForm ? $t('Edit halls') : $t('Add halls') }}</h2>
                                        </div>
                                        <button type="button" @click="addHall" class="btn btn-add-hall"><i class="material-icons">add</i></button>

                                        <div class="row p-0 position-relative mb-4" v-for="(h, index) in cinemasForm.halls" :key="index">
                                            <div class="col-4">
                                                <div class="mb-3">
                                                    <InputComponent name="halls_custom_id" v-model="h.custom_id" :error="errors[`halls.${index}.custom_id`]" :showLabel="true" :placeholder="$t('ID')"/>
                                                </div>
                                            </div>
                                            <div class="col-4">
                                                <div class="mb-3">
                                                    <InputComponent name="halls_name" v-model="h.name" :error="errors[`halls.${index}.name`]" :showLabel="true" :placeholder="$t('Name')"/>
                                                </div>
                                            </div>
                                            <div class="col-4">
                                                <div class="mb-3">
                                                    <InputComponent name="number_of_seats" v-model="h.number_of_seats" :error="errors[`halls.${index}.number_of_seats`]" :showLabel="true" :placeholder="$t('Number of seats')"/>
                                                </div>
                                            </div>
                                            <button v-if="cinemasForm.halls.length > 1" type="button" @click="removeHall(h.id, index)" class="btn btn-remove-hall"><i class="material-icons">remove</i></button>
                                        </div>
                                    </div>

                                    <div class="col-12">
                                        <div class="add-cinema__buttons">
                                            <ButtonComponent :disabled="isButtonDisabled" type="submit" class="me-0 me-sm-4 mb-3 mb-sm-0" theme="primary" :caption="isEditForm ? $t('Save') : $t('Add')"/>
                                            <ButtonComponent type="button" theme="bordered" @click="cancelAddNew" :caption="$t('Cancel')" />
                                        </div>
                                    </div>
                                </div>

                            </FormComponent>
                        </div>
                    </ModalComponent>
                </div>
            </div>
        </div>
        <ModalMini
            :title="$t('Delete item ?')"
            @close="hideModalMini"
            :subtitle="deleteSubtitle"
            @deleteItem="deleteElement"
            :forDelete="true"
            v-if="showDeleteModal"
        />
    </div>
</template>

<script>
    import ButtonComponent from "@/components/ui/ButtonComponent"
    import ModalComponent from "@/components/ui/ModalComponent"
    import FormComponent from "@/components/ui/forms/FormComponent";
    import CheckboxComponent from "@/components/ui/forms/CheckboxComponent";
    import SelectComponent from "@/components/ui/forms/SelectComponent"
    import InputComponent from "@/components/ui/forms/InputComponent"
    import PageHeader from "@/components/PageHeader";
    import Breads from "@/components/Breads";
    import TableMain from "@/components/ui/tables/TableMain"
    import ModalMini from "../../components/ui/ModalMini";

    export default {
        components: { ModalMini, ButtonComponent, TableMain, PageHeader, Breads, ModalComponent, FormComponent, InputComponent, SelectComponent, CheckboxComponent },
        data() {
            return {
                columns: [
                    {
                        id: 1,
                        label: this.$t('ID'),
                        sort: false,
                        attribute: "custom_id",
                    },
                    {
                        id: 2,
                        label: this.$t('Name'),
                        sort: false,
                        attribute: "name",
                    },
                    {
                        id: 3,
                        label: this.$t('Network'),
                        sort: false,
                        attribute: "network.name",
                        urlAttribute: "network_name"
                    },
                    {
                        id: 4,
                        label: this.$t('Company'),
                        sort: false,
                        attribute: "company.name",
                        urlAttribute: "company_name"
                    },
                    {
                        id: 5,
                        label: this.$t('City'),
                        sort: false,
                        attribute: "region.name",
                        urlAttribute: "region_name"
                    },
                    {
                        id: 6,
                        label: this.$t('Status'),
                        sort: false,
                        attribute: "is_active",
                    },
                    {
                        id: 7,
                        label: this.$t('Token'),
                        sort: false,
                        attribute: "api_name",
                    },
                ],
                breadLinks: [
                    {
                        label: this.$t('Cinemas'),
                        to: { name: 'CatalogCinemas' }
                    }
                ],
                showModal: {
                    form: false,
                },
                preloader: true,
                askLeaveAction: {},
                showDeleteModal: false,
                showHalls: false,
                isEditForm: false,
                deleteSubtitle: '',
                cinemasForm: {
                    id: '',
                    name: '',
                    custom_id: '',
                    company_id: '',
                    //api_name: '',
                    halls: [],
                    is_active: false,
                    network_id: '',
                    region_id: ''
                },
                errors: {
                    name: '',
                    custom_id: '',
                    company_id: '',
                    //api_name: '',
                    is_active: '',
                    halls: [{
                        custom_id: '',
                        name: '',
                        number_of_seats: ''
                    }],
                    network_id: '',
                    region_id: ''
                },
                isButtonDisabled: false,
                cinemasMeta: [],
                cinemas: [],
                isCinemasLoaded: false,
                isCompaniesLoaded: false,
                isNetworksLoaded: false,
                isCitiesLoaded: false,
                companies: [],
                removedHalls: [],
                cities: [],
                networks: [],
                roleId: null,
                cinemaId: '',
                cinemaHalls: [],
                askBeforeLeave: false,
                itemForDelete: null,
            }
        },

        computed: {
            // askBeforeLeave() {
            //     let res = Object.keys(this.cinemasForm).filter(elem => {
            //         if (this.cinemasForm[elem] && this.cinemasForm[elem].length > 0) {
            //             return this.cinemasForm[elem].length > 0
            //         }
            //     });
            //     return !!res.length
            // },
            newAddCinemaAction() {
                return this.isEditForm ? `/api/v1/cinemas/${this.cinemaId}` : `/api/v1/cinemas`
            },

            fields() {
                return [
                    {
                        type: "search",
                        by: "custom_id",
                    },
                    {
                        type: "search",
                        by: "name",
                    },
                    {
                        type: "search",
                        by: "network_name",
                    },
                    {
                        type: "search",
                        by: "company_name",
                    },
                    {
                        type: "select",
                        by: "region_id",
                        defaultValue: '',
                        name: "select-region",
                        options: this.cities || [],
                    },
                    {
                        type: "select",
                        by: "is_active",
                        defaultValue: '',
                        name: "select-is-active",
                        isSearchable: false,
                        options: [
                          {
                            id: '1',
                            isActive: true,
                            label: this.$t('Is active'),
                          },
                          {
                            id: '0',
                            isActive: false,
                            label: this.$t('No active'),
                          }
                        ],
                    },
                ]
            }
        },
        watch:{
            cinemasForm:{
                handler: function(){
                    this.askBeforeLeave = true
                },
                deep: true
            }
        },
        methods: {
            addHall() {
                this.cinemasForm.halls.push({
                    id: '',
                    name: ''
                })
            },
            removeHall(i, index) {
                let hall = this.cinemasHalls.filter(e => {
                    return e.id == i
                })

                if (hall && hall[0]) {
                    this.removedHalls.push(i)
                }
                this.cinemasForm.halls = this.cinemasForm.halls.filter((e, ind) => {
                   return ind !== index
                })
            },
            isFormDisable(v) {
                this.isButtonDisabled = v
            },
            hideModalMini(){
                this.showDeleteModal = false
            },
            async deleteElement(){
                let resp = await this.ApiService.cinema.deleteCinema(this.itemForDelete.id)
                if (resp){
                    this.showDeleteModal = false
                    this.itemForDelete = null
                    this.toast.success(this.$t('Cinema successfully removed'))
                    this.getCinemas()
                }
            },
            addCinema() {
                this.clearErrorsAndValues();
                this.isEditForm = false;
                this.showModal.form = true;
                setTimeout(() => this.askBeforeLeave = false, 500)
                this.cinemasForm.halls = []
            },
            getItemForDelete(item){
                this.itemForDelete = item
                this.deleteSubtitle = this.$t('Do you want to delete the') + ' ' + this.itemForDelete.name + ' ' + this.$t('cinema ') + ' ?'
                this.showDeleteModal = true
            },
            async searchCompanies(v) {
                if (!v) {
                    v = undefined
                }
                await this.getCompanies({ name: v })
            },
            async searchCities(v) {
                if (!v) {
                    v = undefined
                }
                await this.getCities({ cities: 1, name: v })
            },
            async updateCinema(id) {
                // await this.getCinemas()
                let updatedCinema = this.cinemas.filter(u => {
                    return u.id == id
                })[0]
                this.clearErrorsAndValues()

                this.isCompaniesLoaded = false
                await this.getCompanies()

                if (updatedCinema?.company && !this.companies.find(x => x.id == updatedCinema?.company.id)) {
                    updatedCinema.company.label = updatedCinema.company.name
                    this.companies.push(updatedCinema.company)
                }
                this.cinemasForm.halls = []

                this.cinemasForm = {
                    id: id,
                    name: updatedCinema.name,
                    custom_id: updatedCinema.custom_id,
                    company_id: updatedCinema.company_id,
                    //api_name: updatedCinema.api_name,
                    is_active: updatedCinema.is_active,
                    network_id: updatedCinema.network_id,
                    region_id: updatedCinema.region_id,
                    halls: updatedCinema.halls || []
                },

                this.isEditForm = true;
                this.showModal.form = true

                // if (!updatedCinema.halls.length) {
                //     this.cinemasForm.halls.push({
                //         id: '',
                //         name: ''
                //     })
                // }
                this.cinemaId = id

                setTimeout(() => this.askBeforeLeave = false, 500)
            },
            clearErrorsAndValues() {
                this.cinemasForm = {
                    id: '',
                    name: '',
                    custom_id: '',
                    company_id: '',
                    //api_name: '',
                    is_active: false,
                    network_id: '',
                    region_id: '',
                    halls: [
                        {
                            id: '',
                            name: '',
                            number_of_seats: ''
                        }
                    ]
                };

                this.errors = {
                    name: '',
                    custom_id: '',
                    company_id: '',
                    //api_name: '',
                    is_active: '',
                    halls: [{
                        id: '',
                        name: '',
                        number_of_seats: ''
                    }],
                    network_id: '',
                    region_id: ''
                },

                this.removedHalls = []
                this.isButtonDisabled = false
            },
            isCinemaActiveChange(v) {
                this.cinemasForm.is_active = v;
            },
            networksChange(v) {
                this.cinemasForm.network_id = v;
            },
            async getCinemas(query = null) {
                if (!query) {
                    if (Object.keys(this.$route.query).length == 0) {
                        this.$router.push({ query: Object.assign({}, {page: 1, sort_by: 'id'}) });
                    }
                    query = this.$route.query
                }
                // this.isCinemasLoaded = false
                let cinemas = await this.ApiService.cinema.getCinemas(query)
                let cinemasHalls = await this.ApiService.cinema.getCinemasHalls()
                this.cinemasHalls = cinemasHalls.data
                this.cinemasMeta = cinemas.meta
                this.cinemas = cinemas.data
                this.$nextTick(() => {
                    // this.isCinemasLoaded = true
                })

                this.preloader = false
            },
            async getCompanies(data = null) {
                let companies = await this.ApiService.company.getCompanies(data)
                this.isCompaniesLoaded = true
                companies.data.map(elem => {
                    elem.label = elem.name
                    return elem
                })
                this.companies = companies.data;
                if (this.$refs.getcompanies) {
                    this.$refs.getcompanies.$forceUpdate();
                }
            },
            async getCities(data = null) {
                let cities = await this.ApiService.city.getCities(data)
                // this.isCitiesLoaded = true
                cities.map(elem => {
                    elem.label = elem.full_name
                    return elem
                })
                this.cities = cities;
                if (this.$refs.getcities) {
                    this.$refs.getcities.$forceUpdate();
                }
            },
            async getNetworks() {
                let networks = await this.ApiService.network.getNetworks()
                this.isNetworksLoaded = true
                networks.data.map(elem => {
                    elem.label = elem.name
                    return elem
                })
                this.networks = networks.data
            },
            cancelAddNew() {
                let res = Object.keys(this.cinemasForm).filter(elem => {
                    if (this.cinemasForm[elem] && this.cinemasForm[elem].length > 0) {
                        return this.cinemasForm[elem].length > 0
                    }
                });
                this.askLeaveAction = { value: !!res.length }
            },
            companiesChange(v) {
                this.cinemasForm.company_id = v;
            },
            citiesChange(v) {
                this.cinemasForm.region_id = v;
            },
            success(type) {
                type == 'create' ? this.toast.success(this.$t('Cinema added')) : this.toast.success(this.$t('Information successfully updated'))
                this.showModal.form = false;
                this.getCinemas();
            },
            errorHandler(errors) {
                this.errors = errors;
            },

        },

        mounted() {
            this.getCinemas()
            this.getCompanies()
            this.getCities({ cities: 1 })
            this.getNetworks()
        },
        created() {
            this.roleId = this.role.getRole()
            if (this.roleId !== 1){

            }
        }
    }
</script>

<style lang="scss">
    .add-cinema {
        width: 750px;
        max-width: 100%;
        margin: 0 auto;

        &__title {
            text-align: center;
            margin-bottom: 30px;
        }

        &__buttons {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 50px;

            @media screen and (max-width: $sm) {
                flex-direction: column;
            }
        }

        &__hr {
          height: 1px;
          background-color: #aebad0;
          margin: 40px 0;
        }
    }

    .btn-add-hall, .btn-remove-hall {
        margin-left: auto;
        display: flex;
        width: 15px;
        margin-right: 23px;
        border-radius: 50%;
        background-color: $color_blue;
        align-items: center;
        justify-content: center;
        height: 15px;
        padding: 0;

        i {
            color: white;
            font-size: 12px;
        }
    }

    .btn-remove-hall {
        position: absolute;
        right: 0;
        top: 45px;
        margin-right: -12px;
    }
    .table{
        tr{
            td:nth-last-child(-n+2){
                @media (min-width: 992px) and (max-width: 1366px){
                    max-width: 120px;
                    overflow-wrap: break-word;
                }
            }
        }
    }
    ::v-deep{


    }
</style>
